import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { useLoaderData, useNavigate, useSearchParams } from "@remix-run/react";
import { RiSearchLine } from "react-icons/ri";
import type { SitemapArgs } from "remix-sitemap";
import { serverOnly$ } from "vite-env-only/macros";
import type { Product } from "~types/api/product.types";

import { AdvertisingCard } from "~/components/AdvertisingCard";
import { ErrorComponent, NotFoundPage } from "~/components/error-boundary";
import { ItemList } from "~/components/item-list";
import { Button } from "~/components/ui/button";
import { ProductCard } from "~/components/ui/cards/product-card";
import { PageContainer } from "~/components/ui/layout/page-container";
import { PageHeading } from "~/components/ui/page-heading";
import { Link, Text } from "~/components/ui/typography";
import {
  DEFAULT_ITEMS_PER_PAGE_OPTION,
  DEFAULT_PRODUCTS_SORTING_OPTION,
  DEFAULT_RANGES_SORTING_OPTION,
  PRODUCTS_SORTING_OPTIONS,
} from "~/config/item-list";
import { categoriesWithRanges } from "~/config/navigation";
import { ECO_PRODUCT_REF } from "~/config/products";
import { getBannerAdvertisings, getThumbnailAdvertisings } from "~/models/advertising.server";
import { getCategoryBySlug } from "~/models/category.server";
import {
  getCharacteristicProductFilters,
  getFilteredBrands,
  getFilteredRanges,
  getProductsPriceRange,
  getStockFilterAvailability,
} from "~/models/filter.server";
import { getProductBySlug, getProducts } from "~/models/product.server";
import { getRanges } from "~/models/range.server";
import { getSession } from "~/models/session.server";
import { getSitemapCategories } from "~/models/sitemap.server";
import { useRootMatchData } from "~/root";
import { getActiveFilters } from "~/services/filters";
import { isProductNotDisplayedOnExtranet } from "~/services/product";
import { NotFoundInApiError, NotFoundPageError } from "~/utils/errors";
import { handleError } from "~/utils/errors.server";
import { getSeoMeta } from "~/utils/seo";

export const sitemap = serverOnly$(async ({ request }: SitemapArgs) => {
  const categories = await getSitemapCategories({ request });
  if (!categories) {
    return [];
  }
  const categoryUrls = categories.map((category) => ({
    loc: `/${category.slug}`,
    exclude: !category,
  }));
  const categoryWithBrands = categories.flatMap((category) =>
    category.brands?.map((brand) => {
      return {
        loc: `/${category.slug}?marques=${brand.slug}`,
        exclude: !brand,
      };
    })
  );

  // const categoryWithFilters = categories.flatMap(async (category) => {
  //   const characteristicFilters = await getCharacteristicProductFilters({
  //     categorySlug: category.slug,
  //     request,
  //   });
  //     const formatedCharacteristicFilters = getFormatedCharacteristicsForFilters({
  //   categorySlug: category.slug || null,
  //   characteristicFilters: characteristicFilters || [],
  // });
  //   return formatedCharacteristicFilters.map((filter) => ({
  //     loc: `/${category.slug}?filtres=${filter.slug}`,
  //     exclude:
  //   }));
  // });

  const staticUrls = [
    "/kits-solaires?filtres=monophase",
    "/kits-solaires?filtres=bifacial",
    "/kits-solaires?filtres=monocristallin",
    "/modules-photovoltaiques?filtres=monocristallin",
    "/modules-photovoltaiques?filtres=bifacial",
    "/modules-photovoltaiques?filtres=transparent",
    "/modules-photovoltaiques?filtres=full-black",
    "/modules-photovoltaiques?filtres=n-type",
    "/modules-photovoltaiques?filtres=bi-verre",
    "/onduleurs-solaires?filtres=triphase",
    "/onduleurs-solaires?filtres=hybride",
    "/onduleurs-solaires?filtres=micro-onduleurs",
  ].map((loc) => ({ loc }));

  return [...categoryUrls, ...categoryWithBrands, ...staticUrls];
});

export const meta: MetaFunction<typeof loader> = (metaArgs) => {
  const loaderData = metaArgs.data;

  const searchParams = new URLSearchParams(metaArgs.location.search);
  const activeFilters = loaderData
    ? getActiveFilters({
        searchParams,
        brands: loaderData.filteredBrands,
        ranges: loaderData.filteredRanges,
        characteristicFilters: loaderData.characteristicFilters,
      })
    : null;

  const title = `${loaderData?.category?.title}${activeFilters?.characteristics?.length === 1 ? ` ${activeFilters?.characteristics[0]?.title}` : ""}${activeFilters?.brands?.length === 1 ? ` ${activeFilters?.brands[0]?.title}` : ""}${activeFilters?.ranges?.length === 1 ? ` ${activeFilters?.ranges[0]?.brandRange.title}` : ""}`;

  return getSeoMeta(metaArgs, {
    title,
    description: `${title} : découvrez nos produits dédiés aux professionnels du photovoltaïque. Powr Connect fournisseur en ${loaderData?.category?.title} pour les pros`,
    selfCanonical: false,
    canonicalPath: `${metaArgs.location.pathname}${metaArgs.location.search}`,
  });
};

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
  try {
    const categorySlug = params["category"] ?? null;

    if (!categorySlug || categorySlug === "") {
      return redirect("/", { status: 302 });
    }

    const category = await getCategoryBySlug({ slug: categorySlug, request }).catch((_err) => null);

    if (!category) {
      // It's an old product url structure
      const product = await getProductBySlug({ slug: categorySlug, request }).catch((error) => {
        if (error instanceof NotFoundInApiError) {
          throw new NotFoundPageError(
            `Cette catégorie ou ce produit n'existe pas ou n'est plus disponible.`
          );
        }
        throw error;
      });

      if (
        !product ||
        !product.brand ||
        !product.categories ||
        product.categories.length < 1 ||
        product.deletedAt ||
        product.typeProduct === 2 ||
        product.reference === ECO_PRODUCT_REF ||
        isProductNotDisplayedOnExtranet(product)
      ) {
        if (product?.categories?.[0]?.slug) {
          return redirect(`/${product.categories[0].slug}`, { status: 301 });
        }
        throw new NotFoundPageError(
          `Cette catégorie ou ce produit n'existe pas ou n'est plus disponible.`
        );
      }

      // Redirect to the new product url structure
      return redirect(`/${product.categories[0]?.slug}/${product.slug}`, {
        status: 301,
      });
    }

    const session = await getSession({ request });
    const activeContactId = session.get("activeContactId") || null;

    const url = new URL(request.url);

    const [
      characteristicFilters,
      productsResponse,
      filteredBrandsResponse,
      filteredRangesResponse,
      ranges,
      productsPriceRange,
      isStockFilterAvailable,
      bannerAdvertisings,
      thumbnailAdvertisings,
    ] = await Promise.all([
      getCharacteristicProductFilters({
        categorySlug,
        searchParams: url.searchParams,
        request,
      }),
      getProducts({
        searchParams: url.searchParams,
        categorySlug,
        sortParam:
          url.searchParams.get("sort-by") ||
          DEFAULT_PRODUCTS_SORTING_OPTION[
            categorySlug as keyof typeof DEFAULT_PRODUCTS_SORTING_OPTION
          ] ||
          DEFAULT_PRODUCTS_SORTING_OPTION.default,
        page: Number(url.searchParams.get("page") || 1),
        itemsPerPage: Number(
          url.searchParams.get("items-per-page") || DEFAULT_ITEMS_PER_PAGE_OPTION
        ),
        request,
      }),
      getFilteredBrands({
        categorySlug,
        searchParams: url.searchParams,
        request,
      }),
      getFilteredRanges({
        categorySlug,
        searchParams: url.searchParams,
        request,
      }),
      categorySlug && categoriesWithRanges.includes(categorySlug)
        ? getRanges({
            brands: url.searchParams.get("marques"),
            filters: url.searchParams.get("filtres"),
            sortParam: DEFAULT_RANGES_SORTING_OPTION,
            search: url.searchParams.get("search"),
            page: 1,
            itemsPerPage: 1500,
            request,
          })
        : null,
      categorySlug && activeContactId
        ? getProductsPriceRange({
            categorySlug,
            searchParams: url.searchParams,
            request,
          })
        : null,
      categorySlug && activeContactId
        ? getStockFilterAvailability({
            categorySlug,
            searchParams: url.searchParams,
            request,
          })
        : null,
      getBannerAdvertisings({
        categorySlug,
        searchParams: url.searchParams,
        request,
      }),
      getThumbnailAdvertisings({
        categorySlug,
        searchParams: url.searchParams,
        request,
      }),
    ]);

    return json({
      category,
      products: productsResponse.data,
      totalItems: productsResponse.totalItems,
      characteristicFilters,
      productsPriceRange,
      isStockFilterAvailable,
      filteredBrands: filteredBrandsResponse.data,
      filteredRanges: filteredRangesResponse.data,
      ranges: ranges?.data,
      bannerAdvertisings,
      thumbnailAdvertisings,
      globalError: null,
    });
  } catch (error) {
    return handleError(error, {
      category: null,
      products: null,
      totalItems: null,
      characteristicFilters: null,
      productsPriceRange: null,
      filteredBrands: null,
      filteredRanges: null,
      isStockFilterAvailable: null,
      ranges: null,
      bannerAdvertisings: null,
      thumbnailAdvertisings: null,
    });
  }
};

const CategoryProductsPage = () => {
  const rootData = useRootMatchData();
  const data = useLoaderData<typeof loader>();
  const {
    category,
    products,
    totalItems,
    characteristicFilters,
    productsPriceRange,
    filteredBrands,
    filteredRanges,
    isStockFilterAvailable,
    ranges,
    bannerAdvertisings,
    thumbnailAdvertisings,
  } = data;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Breadcrumbs
  const breadcrumbData = [
    {
      name: category?.title || "",
    },
  ];

  const activeFilters = getActiveFilters({
    searchParams,
    brands: filteredBrands,
    ranges: filteredRanges,
    characteristicFilters,
  });

  const title = `${category?.title}${activeFilters?.characteristics?.length === 1 ? ` - ${activeFilters?.characteristics[0]?.title}` : ""}${activeFilters?.brands?.length === 1 ? ` - ${activeFilters?.brands[0]?.title}` : ""}${activeFilters?.ranges?.length === 1 ? ` - ${activeFilters?.ranges[0]?.brandRange.title}` : ""}`;

  return (
    <PageContainer className="!pb-0">
      <PageHeading
        breadcrumbData={breadcrumbData}
        HeadingIconComponent={<RiSearchLine />}
        headingText={title || ""}
        advertising={bannerAdvertisings?.[0] || null}
      />
      <ItemList
        category={category}
        characteristicFilters={characteristicFilters}
        brands={filteredBrands || null}
        ranges={ranges || null}
        totalItems={totalItems || 0}
        totalItemsText={`Nombre de produits : ${totalItems || 0}`}
        sortingOptions={PRODUCTS_SORTING_OPTIONS}
        defaultSortingOption={
          DEFAULT_PRODUCTS_SORTING_OPTION[
            category?.slug as keyof typeof DEFAULT_PRODUCTS_SORTING_OPTION
          ] || DEFAULT_PRODUCTS_SORTING_OPTION.default
        }
        filteredRanges={filteredRanges || null}
        isStockFilterAvailable={isStockFilterAvailable}
        priceRange={productsPriceRange || null}
        comparator={rootData?.comparator || null}
        isUserConnected={!!rootData?.contact?.id}
        isEmpty={!products || products.length === 0}
      >
        <>
          {products && products.length > 0 ? (
            <>
              {thumbnailAdvertisings[0] ? (
                <AdvertisingCard advertising={thumbnailAdvertisings?.[0]} />
              ) : null}
              {products.map((product: Product) => {
                return (
                  <ProductCard
                    key={product.id}
                    product={product}
                    comparator={rootData?.comparator || null}
                    contact={rootData?.contact || null}
                  />
                );
              })}
            </>
          ) : (
            <div className="mx-auto">
              {activeFilters.rangesParams && activeFilters.rangesParams.length === 1 ? (
                <>
                  <Text muted className="mb-8 text-center">
                    Si vous souhaitez commander des produits de la gamme{" "}
                    {activeFilters.rangesParams[0]?.slug.toUpperCase()}, merci de{" "}
                    <Link to="/nous-contacter">nous contacter</Link>.
                  </Text>
                  <div className="mx-auto flex justify-center gap-4">
                    <Button onClick={() => navigate(-1)} variant="outline">
                      Retour à la page précédente
                    </Button>
                    <Button as="link" to="/nous-contacter">
                      Nous contacter
                    </Button>
                  </div>
                </>
              ) : (
                <Text muted className="mb-8 text-center">
                  Aucun produit n&apos;a été trouvé.
                  <br />
                  Essayez de modifier vos filtres.
                </Text>
              )}
            </div>
          )}
        </>
      </ItemList>
    </PageContainer>
  );
};

export default CategoryProductsPage;

export const ErrorBoundary = () => {
  return (
    <ErrorComponent
      statusHandlers={{
        404: ({ error }) => <NotFoundPage>{error.data}</NotFoundPage>,
      }}
    />
  );
};
